import { LockClosed_md as LockIcon } from "components/Heroicons";

export const LoggedOutScreen = () => {
  return (
    <div className="bg-gray-50 sm:px-6 lg:px-8 flex flex-col justify-center min-h-screen py-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-md mt-8">
        <div className="sm:rounded-lg sm:px-10 px-4 py-8 bg-white shadow-lg">
          <div className="flex flex-col items-center">
            {/* Icon */}
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
              <LockIcon className="w-6 h-6 text-green-600" />
            </div>

            {/* Title */}
            <h2 className="mt-6 text-2xl font-bold text-center text-gray-900">
              You've Been Logged Out
            </h2>

            {/* Message */}
            <p className="max-w-sm mt-2 text-sm text-center text-gray-600">
              You have successfully logged out of your account.
            </p>

            {/* Login instructions */}
            <p className="mt-6 text-sm text-center text-gray-900">
              To login again please return to CarriersWeb
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
