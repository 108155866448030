import { useState, useCallback } from "react";
import gql from "graphql-tag";
import { useIdleTimer } from "react-idle-timer";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { Modal } from "../Modal";
import { useInterval } from "hooks/useInterval";
import { Button } from "@ovicare/ui";

const TIMEOUT_DURATION = 20 * 60 * 1000; // 20 minutes
const WARNING_BEFORE = 5 * 60 * 1000; // 5 minutes
const CHECK_INTERVAL = 1000; // Check every second

const formatTime = (ms: number) => {
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

interface MutationData {
  logout: boolean;
}

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const IdleTimeoutHandler = () => {
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(WARNING_BEFORE);

  const [logout] = useMutation<MutationData>(LOGOUT);
  const history = useHistory();
  const { logout: forgetToken } = useAuth();

  const handleTimeout = useCallback(
    async (nextPath?: string) => {
      setIsWarningOpen(false);
      try {
        await logout();
      } catch (e) {
        console.error("Failed to logout", e);
      }
      forgetToken();
      history.push(nextPath || "/session_expired");
    },
    [logout, forgetToken, history]
  );

  const handleOnIdle = () => {
    handleTimeout();
  };

  const handleUserActivity = () => {
    if (isWarningOpen) {
      setIsWarningOpen(false);
    }
  };

  const { reset, getLastActiveTime } = useIdleTimer({
    timeout: TIMEOUT_DURATION,
    onIdle: handleOnIdle,
    onActive: handleUserActivity,
    onAction: handleUserActivity,
    debounce: 500,
    events: ["mousedown", "keydown", "touchstart", "scroll"],
  });

  // Check for showing warning and update countdown
  useInterval(() => {
    const lastActive = getLastActiveTime();
    const remaining = lastActive + TIMEOUT_DURATION - Date.now();

    if (remaining <= WARNING_BEFORE && remaining > 0) {
      if (!isWarningOpen) {
        setIsWarningOpen(true);
      }
      setTimeRemaining(remaining);
    }
  }, CHECK_INTERVAL);

  const forceLogout = useCallback(() => {
    handleTimeout("/logged_out");
  }, [handleTimeout]);

  return (
    <Modal
      isOpen={isWarningOpen}
      onRequestClose={() => {
        reset();
        setIsWarningOpen(false);
      }}
      className="max-w-lg"
    >
      <div className="p-6">
        <h3 className="mb-4 text-lg font-medium text-gray-900">
          Session Timeout Warning
        </h3>
        <div className="md:py-10 py-4 mb-6 text-center">
          <p className="mb-2 text-lg font-bold text-gray-500">
            Your session will expire due to inactivity in:
          </p>
          <p className="font-mono text-6xl font-bold text-red-600">
            {formatTime(timeRemaining)}
          </p>
        </div>
        <div className="sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 mt-5">
          <Button
            type="button"
            kind="secondary"
            className="justify-center"
            onClick={forceLogout}
          >
            Logout now
          </Button>
          <Button
            type="button"
            kind="primary"
            color="gold"
            className="justify-center"
            onClick={() => {
              reset();
              setIsWarningOpen(false);
            }}
          >
            Stay logged in
          </Button>
        </div>
      </div>
    </Modal>
  );
};
